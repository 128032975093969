<template>
  <div class="card card-custom">
    <div class="card-body p-0">
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-21">
          <b-tabs content-class="mt-3">
            <b-tab title="Contact Info" active>
              <ContactInfo></ContactInfo>
            </b-tab>
            <b-tab title="Restaurant Type">
              <RestaurantType></RestaurantType>
            </b-tab>
            <b-tab title="Branding">
              <Branding></Branding>
            </b-tab>
            <b-tab title="Social Links">
              <Social></Social>
            </b-tab>
          </b-tabs>
        </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import ContactInfo from "@/view/pages/business/components/ContactInfo";
import Branding from "@/view/pages/business/components/Branding";
import Social from "@/view/pages/business/components/Social";
import RestaurantType from "@/view/pages/business/components/RestaurantType";

export default {
  name: "BusinessInfo",
  components: {
    ContactInfo,
    Branding,
    Social,
    RestaurantType
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Business Settings" },
      { title: "Business Info" }
    ]);
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-primary"
      });
    }
  }
};
</script>
