<template>
  <!--begin::Card-->
  <div>

    <h4 class="mb-15 font-weight-bold text-dark">
      Setup Your Branding <br>
      <small>The Branding section of website allows you to customize the images and text on your website. If you have your own images stored on your PC press the select image button and find the image to upload it to your website. If you do not have your own images you can add copyright free images from pixabay instead.</small>
    </h4>
    <form
      class="form"
      novalidate="novalidate"
      id="branding_form"
      ref="form"
      v-on:submit="save"
    >
      <div class="form-group">
        
        <label>Logo Image</label>
        &nbsp;&nbsp;
        <i class="wizard-icon flaticon-questions-circular-button" @mouseover="toggleLogoImg" @mouseout="toggleLogoImg"></i>
        <img style="border: 1px solid black;margin-left:10px;z-index: 99999999; position: absolute;height:80px;" v-show="logoImg" :src="imgURL +'IconImages/logo.png'" alt="image">
        <b-form-file
          v-model="form.logo"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ logoPic }}
        <span class="form-text text-muted"
          >The logo image shows in the top left corner of the website next to your business information. Enter the URL where your logo is stored, The correct sizing of this image should be a square 120 pixels x 120 pixels.</span
        >
      </div>

      <div class="form-group">
        
        <label>Favicon Icon</label>
        &nbsp;&nbsp;
        <b-form-file
          v-model="form.favicon"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="favicon"
        ></b-form-file>
        {{ faviconPic }}
        <span class="form-text text-muted"
          >Favicon Icon will be shown</span
        >
      </div>

      <div class="form-group">
        <label>Banner Image</label>
        &nbsp;&nbsp;
        <i class="wizard-icon flaticon-questions-circular-button" @mouseover="toggleBannerImg" @mouseout="toggleBannerImg"></i>
        <img style="border: 1px solid black;margin-left:10px;z-index: 99999999; position: absolute;height:400px" v-show="bannerImg" :src="imgURL +'IconImages/banner.png'" alt="image">
        <b-form-file
          v-model="form.banner1"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ bannerPic1 }}
        <span class="form-text text-muted"
          >Image will be shown in Banner</span
        >
      </div>
      <div class="form-group">
        <label>Banner Image Title</label>
        <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        placeholder="Banner Image Text"
        v-model="form.banner1_text"
        />
      </div>
      <div class="form-group">
        <label>Promotion 1 Image</label>
        &nbsp;&nbsp;
        <i class="wizard-icon flaticon-questions-circular-button" @mouseover="togglePromo1" @mouseout="togglePromo1"></i>
        <img style="border: 1px solid black;margin-left:10px;z-index: 99999999; position: absolute;height:400px" v-show="promoImg1" :src="imgURL +'IconImages/promo1.png'" alt="image">
        <b-form-file
          v-model="form.promotion1"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ promotion1 }}
        <span class="form-text text-muted"
          >Promotion images will be shown</span
        >
      </div>

      <div class="form-group">
        <label>Promotion 2 Image</label>
        &nbsp;&nbsp;
        <i class="wizard-icon flaticon-questions-circular-button" @mouseover="togglePromo2" @mouseout="togglePromo2"></i>
        <img style="border: 1px solid black;margin-left:10px;z-index: 99999999; position: absolute;height:400px" v-show="promoImg2" :src="imgURL +'IconImages/promo2.png'" alt="image">
        <b-form-file
          v-model="form.promotion2"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ promotion2 }}
        <span class="form-text text-muted"
          >Promotion images will be shown</span
        >
      </div>

      <div class="form-group">
        <label>Promotion 3 Image</label>
        &nbsp;&nbsp;
        <i class="wizard-icon flaticon-questions-circular-button" @mouseover="togglePromo3" @mouseout="togglePromo3"></i>
        <img style="border: 1px solid black;margin-left:10px;z-index: 99999999; position: absolute;height:400px" v-show="promoImg3" :src="imgURL +'IconImages/promo3.png'" alt="image">
        <b-form-file
          v-model="form.promotion3"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ promotion3 }}
        <span class="form-text text-muted"
          >Promotion images will be shown</span
        >
      </div>

      <div class="form-group">
        <label>Promotion 4 Image</label>
        &nbsp;&nbsp;
        <i class="wizard-icon flaticon-questions-circular-button" @mouseover="togglePromo4" @mouseout="togglePromo4"></i>
        <img style="border: 1px solid black;margin-left:10px;z-index: 99999999; position: absolute;height:400px" v-show="promoImg4" :src="imgURL +'IconImages/promo4.png'" alt="image">
        <b-form-file
          v-model="form.promotion4"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ promotion4 }}
        <span class="form-text text-muted"
          >Promotion images will be shown</span
        >
      </div>
      <div class="form-group">
        <label>Sub Section 1 Title</label>
        <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        placeholder="Sub Section 1"
        v-model="subSectionTitle1"
        />
      </div>
      <div class="form-group">
        <label>Sub Section 1 Image</label>
        <b-form-file
          v-model="form.subSection1"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ subSection1 }}
      </div>
      <div class="form-group">
        <label>Sub Section 1 Image</label>
        <b-form-file
          v-model="form.subSection2"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ subSection2 }}
      </div>
      <div class="form-group">
        <label>Sub Section 1 Image</label>
        <b-form-file
          v-model="form.subSection3"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ subSection3 }}
      </div>
      <div class="form-group">
        <label>Sub Section 1 Image</label>
        <b-form-file
          v-model="form.subSection4"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ subSection4 }}
      </div>
      <div class="form-group">
        <label>Sub Section 2 Title</label>
        <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        placeholder="Sub Section 2"
        v-model="subSectionTitle2"
        />
      </div>
      <div class="form-group">
        <label>Sub Section 2 Image</label>
        <b-form-file
          v-model="form.subSection5"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ subSection5 }}
      </div>
      <div class="form-group">
        <label>Sub Section 2 Image</label>
        <b-form-file
          v-model="form.subSection6"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ subSection6 }}
      </div>
      <div class="form-group">
        <label>Sub Section 2 Image</label>
        <b-form-file
          v-model="form.subSection7"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ subSection7 }}
      </div>
      <div class="form-group">
        <label>Sub Section 2 Image</label>
        <b-form-file
          v-model="form.subSection8"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          name="logo"
        ></b-form-file>
        {{ subSection8 }}
      </div>
      

      <button
        :style="{'display': $route.name === 'wizard-branding-details' ? 'block' : 'none'}"
        ref="form_submit"
        class="btn btn-primary mt-3 mr-4"
      >
        Submit
      </button>
  </form>
  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  name: "Branding",
  data() {
    return {
      logoImg : false,
      bannerImg : false,
      promoImg1 : false,
      promoImg2 : false,
      promoImg3 : false,
      promoImg4 : false,
      form:{
        logo: null,
        favicon: null,
        banner1 : null,
        banner1_text : '',
        promotion1 : null,
        promotion2 : null,
        promotion3 : null,
        promotion4 : null,
        subSection1 : null,
        subSection2 : null,
        subSection3 : null,
        subSection4 : null,
        subSection5 : null,
        subSection6 : null,
        subSection7 : null,
        subSection8 : null,
      },
      logoPic : '',
      faviconPic : '',
      bannerPic1 : '',
      bannerPic4 : '',
      promotion1 : '',
      promotion2 : '',
      promotion3 : '',
      promotion4 : '',
      subSection1 : '',
      subSection2 : '',
      subSection3 : '',
      subSection4 : '',
      subSection5 : '',
      subSection6 : '',
      subSection7 : '',
      subSection8 : '',
      subSectionTitle1 : '',
      subSectionTitle2 : '',
      isValid: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    imgURL ()
    {
      return process.env.VUE_APP_API_BASE_URL;
    }
  },
   mounted() {
     this.form.logo = null;
     this.logoPic = null;
    this.get();
    const form = KTUtil.getById("branding_form");
    this.fv = formValidation(form, {
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      //this.save();
    });

    this.fv.on("core.form.invalid", () => {
      this.$emit('validation', false);
    });
  },
  methods: {
    toggleLogoImg() {
      this.logoImg = ! this.logoImg
    },
    toggleBannerImg() {
      this.bannerImg = ! this.bannerImg
    },
    togglePromo1() {
      this.promoImg1 = ! this.promoImg1
    },
    togglePromo2() {
      this.promoImg2 = ! this.promoImg2
    },
    togglePromo3() {
      this.promoImg3 = ! this.promoImg3
    },
    togglePromo4() {
      this.promoImg4 = ! this.promoImg4
    },
    get() {
      ApiService.get('business/settings/wizard', 'branding')
      .then(({ data }) => {
        this.logoPic = data.data.logo
        this.faviconPic = data.data.faviconPic
        this.bannerPic1 = data.data.banner1
        this.form.banner1_text = data.data.banner1_text
        this.promotion1 = data.data.promotion1
        this.promotion2 = data.data.promotion2
        this.promotion3 = data.data.promotion3
        this.promotion4 = data.data.promotion4
        this.subSectionTitle1 = data.data.subSectionTitle1
        this.subSectionTitle2 = data.data.subSectionTitle2
        this.subSection1 = data.data.subSection1
        this.subSection2 = data.data.subSection2
        this.subSection3 = data.data.subSection3
        this.subSection4 = data.data.subSection4
        this.subSection5 = data.data.subSection5
        this.subSection6 = data.data.subSection6
        this.subSection7 = data.data.subSection7
        this.subSection8 = data.data.subSection8
        
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response.data.errors);
      });
    },
    save() {
      let currentUserId = this.currentUser.id;
      let formData = new FormData();
      formData.append('banner1_text', this.form.banner1_text);
      formData.append('subSectionTitle1', this.subSectionTitle1);
      formData.append('subSectionTitle2', this.subSectionTitle2);

      if (this.form.logo)
      {
        formData.append('logo', this.form.logo);
      }
      if (this.form.favicon)
      {
        formData.append('favicon', this.form.favicon);
      }
      if (this.form.banner1)
      {
        formData.append('banner1', this.form.banner1);
        
      }
      if (this.form.promotion1)
      {
        formData.append('promotion1', this.form.promotion1);
      }
      if (this.form.promotion2)
      {
        formData.append('promotion2', this.form.promotion2);
      }
      if (this.form.promotion3)
      {
        formData.append('promotion3', this.form.promotion3);
      }
      if (this.form.promotion4)
      {
        formData.append('promotion4', this.form.promotion4);
      }
      if (this.form.subSection1)
      {
        formData.append('subSection1', this.form.subSection1);
      }
      if (this.form.subSection2)
      {
        formData.append('subSection2', this.form.subSection2);
      }
      if (this.form.subSection3)
      {
        formData.append('subSection3', this.form.subSection3);
      }
      if (this.form.subSection4)
      {
        formData.append('subSection4', this.form.subSection4);
      }
      if (this.form.subSection5)
      {
        formData.append('subSection5', this.form.subSection5);
      }
      if (this.form.subSection6)
      {
        formData.append('subSection6', this.form.subSection6);
      }
      if (this.form.subSection7)
      {
        formData.append('subSection7', this.form.subSection7);
      }
      if (this.form.subSection8)
      {
        formData.append('subSection8', this.form.subSection8);
      }
      
        ApiService.fileUpload(
          'business/settings/wizard/store/branding/'+currentUserId,
          formData
        )
        .then(({ data }) => {
            sessionStorage.removeItem("personalInfo");
            this.$store.dispatch(UPDATE_PERSONAL_INFO);
          if  (this.$route.name === 'wizard-branding-details') {
            Swal.fire("Success", "Branding details updated successfully", "success");
          }
          this.$emit('validation', true);
        })
        .catch(({ response }) => {
          this.$emit('validation', false);
        });
        
    },
    onSubmit() {
      this.$refs.form_submit.click();
    }
  }
};
</script>
